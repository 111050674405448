import { ReactNode } from "react";
import styled from "styled-components";
import { Card, CardBody, Box, CardProps } from "@pancakeswap/uikit";

const StyledCard = styled(Card)<{ background: string; rotation?: string }>`
  height: fit-content;
  border: none;
  border-bottom: 4px solid #ffbf00;

  ${({ theme }) => theme.mediaQueries.md} {
    ${({ rotation }) => (rotation ? `transform: rotate(${rotation});` : "")}
  }
`;

const IconWrapper = styled(Box)<{ rotation?: string }>`
  position: absolute;
  top: 24px;
  right: 24px;

  ${({ theme }) => theme.mediaQueries.md} {
    ${({ rotation }) => (rotation ? `transform: rotate(${rotation});` : "")}
  }
`;

interface IconCardProps extends IconCardData, CardProps {
  children: ReactNode;
}

export interface IconCardData {
  icon: ReactNode;
  background?: string;
  borderColor?: string;
  rotation?: string;
}

const IconCard: React.FC<React.PropsWithChildren<IconCardProps>> = ({
  icon,
  background,
  // borderColor,
  rotation,
  children,
  ...props
}) => {
  return (
    <StyledCard background={background} rotation={rotation} {...props}>
      <CardBody style={{ background: background }}>
        <IconWrapper rotation={rotation}>{icon}</IconWrapper>
        {children}
      </CardBody>
    </StyledCard>
  );
};

export default IconCard;
